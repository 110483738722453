<template>
  <div class="module">
    <b-row>
      <b-col
        v-if="dataLoad"
        cols="12"
        md="12"
      >
        <div
          class=" text-center my-2"
        >
          <b-spinner
            type="grow"
            label="Loading..."
          />
        </div>
      </b-col>
      <b-col
        v-for="(modulex, idx) in modules"
        v-else
        :key="idx"
        cols="12"
        md="4"
      >
        <b-card>
          <div class="title d-flex justify-content-between">
            <h4>Module Name: {{ modulex.name }}</h4>
            <p>
              <b-badge
                :variant="modulex.status === 1 ? 'success' : 'danger'"
                class="btn change-status-module"
                @click="changeModuleStatus(modulex.status, modulex.name)"
              >
                {{ convertModuleStatus(modulex.status) }}
              </b-badge>
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'

export default {
  setup() {
    const getModules = () => store.dispatch('moduleStates/getModules')
    getModules()
    const modules = computed(() => store.state.moduleStates.modules)
    const dataLoad = computed(() => store.state.moduleStates.dataLoad)

    return {
      modules,
      getModules,
      dataLoad,
    }
  },
  methods: {
    // async getModules() {
    //   const modules = []
    //   this.dataLoad = true
    //   try {
    //     const { data } = await axios.get('modules')
    //     // eslint-disable-next-line guard-for-in
    //     for (const name in data.data) {
    //       modules.push(data.data[name])
    //     }
    //     this.modules = modules
    //   } catch (error) {
    //     if (error.response.status === 500) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Server Error',
    //           icon: 'BellIcon',
    //           variant: 'danger',
    //         },
    //       })
    //     }
    //   } finally {
    //     this.dataLoad = false
    //   }
    // },
    convertModuleStatus(status) {
      if (status === 1) return 'active'
      return 'not active'
    },
    changeModuleStatus(status, name) {
      this.$swal({
        title: 'Are you sure that you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!',
      }).then(result => {
        if (result.isConfirmed) {
          if (status === 1) {
            this.dataLoad = true
            axios.post('modules/edit-active-status', { name, status: 0 }).then(res => {
              if (res.status === 200) {
                this.$swal(
                  'Changed!',
                  'Changed Successfully.',
                  'success',
                )
                this.getModules()
              }
            }).finally(() => {
              this.dataLoad = false
            })
          } else {
            this.dataLoad = true
            axios.post('modules/edit-active-status', { name, status: 1 }).then(res => {
              if (res.status === 200) {
                this.$swal(
                  'Changed!',
                  'Changed Successfully.',
                  'success',
                )
                this.getModules()
              }
            }).finally(() => {
              this.dataLoad = false
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.change-status-module {
  color: white !important;
}
</style>
